var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-page-tab" },
    _vm._l(_vm.pageTabs, function (pageTab, index) {
      return _c("router-link", { key: index, attrs: { to: pageTab.path } }, [
        _c(
          "span",
          {
            staticClass: "table-tab-item",
            class: {
              isActive: _vm.filterIndex == pageTab.tabIndex,
              "reset-width": pageTab.resetWidth,
            },
            on: {
              click: function ($event) {
                _vm.inTab && _vm.changeTabItem(pageTab)
              },
            },
          },
          [
            _vm._v("\n            " + _vm._s(pageTab.title) + "\n            "),
            pageTab.tabIndex == 2 && pageTab.title == "未申请的开票"
              ? _c("i", { staticClass: "num-icon" }, [
                  _vm._v(_vm._s(_vm.unApplyNumber)),
                ])
              : _vm._e(),
          ]
        ),
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }