<template>
    <div class="table-page-tab">
        <router-link 
            v-for="(pageTab, index) in pageTabs" 
            :key="index" 
            :to="pageTab.path">
            <span class="table-tab-item" 
                :class="{
                    'isActive': filterIndex == pageTab.tabIndex,
                    'reset-width': pageTab.resetWidth }" 
                @click="inTab && changeTabItem(pageTab)"
                >
                {{pageTab.title}}
                <i class="num-icon" v-if="pageTab.tabIndex == 2 && pageTab.title == '未申请的开票'">{{unApplyNumber}}</i>
            </span>
        </router-link>
        <!-- <span class="point-tip" v-if="isMyOrder && $store.state.user.userInfo.isCFUser">及时变更职位招聘状态每次可得5积分</span> -->
    </div>
</template>

<script>
export default {
    name:'table-page-tab',
    props: {
        filterIndex: {
            default: 0,
            type: Number
        },
        pageTabs: {
            type: Array,
            default() {
                return [{
                    enTitle: "",
                    cnTitle: "",
                    path: "/",
                    title: "",
                    aliveComponent: "",
                    tabIndex: 0
                }]
            }
        },
        unApplyNumber: {
            default: 0,
            type: Number
        },
        inTab: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        isMyOrder() {
            return location.hash.indexOf('myOrders/createdJob') > -1
        }
    },
    methods: {
        changeTabItem(pageTab) {
            let tabItem = {
                enTitle: pageTab.enTitle,
                cnTitle: pageTab.cnTitle,
                aliveComponent: pageTab.aliveComponent,
                path: pageTab.path,
                isActive: true
            };
            let tabItems = JSON.parse(sessionStorage.getItem("tabItems")) || [];
            let _index = tabItems.findIndex(item => item.enTitle === pageTab.enTitle);
            tabItems.splice(_index, 1, tabItem);
            this.$store.dispatch('workbench/invokeSetTabItems', tabItems);
            this.$store.dispatch('workbench/invokeSwitchTab', _index);
        },
    }
}
</script>


<style lang="scss" scoped>
.table-page-tab {
    flex-shrink: 0;
    border-radius: 0 0 2px 2px;
    color: #999;
    font-size: 14px;
    height: 30px;
    line-height: 28px;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 1px;

    .point-tip {
        font-size: 14px;
        color: #EE6E4F;
        margin-left: 10px;
    }

    .table-tab-item {
        width: 133px;
        display: inline-block;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;
        border: 1px solid #ddd;
        text-align: center;
        background-color: #F8F8F8;
        &.isActive {
            width: 123px;
            background-color: $primary;
            color: #fff;
            border-color: $primary;
        }
        .num-icon{
            display: inline-block;
            min-width: 18px;
            height: 18px;
            line-height: 18px;
            background: #F5A623;
            border-radius: 9px;
            font-size: 12px;
            color: white;
            padding: 0 2px;
        }
    }
    .reset-width{
        width: 173px!important;
    }
    a {
        color: #999;
        &:hover {
            color: $primary;
        }
    }
    .router-link-active {
        .isActive {
            &::before {
                content: "";
                width: 10px;
                height: 31px;
                display: inline-block;
                position: absolute;
                left: -6px;
                top: -1px;
                background-color: inherit;
                transform: rotate(15deg);
                z-index: 1;
            }
            &::after {
                content: "";
                width: 10px;
                height: 31px;
                display: inline-block;
                position: absolute;
                right: -6px;
                top: -3px;
                background-color: inherit;
                transform: rotate(15deg);
                z-index: 1;
            }
        }
        &:nth-of-type(1) {
            .isActive {
                width: 128px;
                &::before {
                    display: none;
                }
            }
        }
        &:nth-last-of-type(1) {
            .isActive {
                width: 128px;
                &::after {
                    display: none;
                }
            }
        }
    }
}
</style>
